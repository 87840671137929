.content {
  @apply leading-normal overflow-y-auto;
  max-height: 40vh;

  :global {
    .is-editor-empty:first-child::before {
      @apply text-gray-500 float-left h-0 pointer-events-none;
      content: attr(data-placeholder);
    }

    h2,
    h3 {
      @apply text-gray-700;
    }

    blockquote {
      @apply border-l-2 border-gray-400 text-gray-600;
    }

    .divider,
    hr {
      @apply bg-gray-400;
    }

    a:not([data-button]):not(.button) {
      @apply text-teal-300 underline;
    }

    .button {
      @apply bg-gray-900 text-white font-medium text-sm px-5 py-3 rounded-lg shadow-sm no-underline hover:bg-gray-700 transition-all duration-200 cursor-pointer inline-flex;
    }
  }
}
